const ProductsPage = () => {
    function redirectToProductsSection() {
        if (typeof window !== `undefined`) {
            window.location.replace("/#products-section-anchor");
        }
    }
    redirectToProductsSection();
    return null;
};
export default ProductsPage;
